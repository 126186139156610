$('document').ready(function(){
    $( "#datepicker" ).datepicker({ dateFormat: 'dd/mm/yy', firstDay: 1,autoUpdateInput: false   });
    // $( "#datepicker-van" ).datepicker({ dateFormat: 'dd/mm/yy', firstDay: 1 });
    $( "#datepicker-van" ).datetimepicker({
        dateFormat: 'dd/mm/yy',
    });
    $( "#datepicker-tot" ).datetimepicker({
        dateFormat: 'dd/mm/yy',
    });

    $('.laat').on('click', function(){
        var id = $(this).attr('for');
        if ($(this).find('input').is(':checked')) {
            $('.laat-waarom[trigger=' + id + ']').show();
            $('.laat-waarom[trigger=' + id + ']').css({'display': 'block'});
        } else {
            $('.laat-waarom[trigger=' + id + ']').hide();
            $('.laat-waarom[trigger=' + id + ']').css({'display': 'none'});
        }
    });

    $('.afw').on('click', function(){
        var id = $(this).attr('for');
        if ($(this).find('input').is(':checked')) {
            $('.afwezig-waarom[trigger=' + id + ']').hide();
            $('.afwezig-waarom[trigger=' + id + ']').css({'display': 'none'});
        } else {
            $('.afwezig-waarom[trigger=' + id + ']').show();
            $('.afwezig-waarom[trigger=' + id + ']').css({'display': 'block'});
        }
    });

    $('.info-block').on('click', function(){
        console.log($(this).attr('info-block'));
        $('.beschrijving').hide();
        $('.overlay').show();

        $('.beschrijving.' + $(this).attr('info-block')).show();
    });

    $('#summernote').summernote();

    $('input[type=checkbox]').on('change', function(){
        $(this).parent().parent().toggleClass("active-user");
    });

    $('.stagiaire').on('change', function(){
        if ($(this).val() == 'Vrijwilliger') {
            $('.stagiaire-block').addClass('hidden');
        } else {
            $('.stagiaire-block').removeClass('hidden');
        }
    });

    // Pop-up kennismakingsfiche
    $('.lln-cb').on('click', function(){
        var path = $(this).find('#path').val();

        if (path != "") {
            if ($(this).find('label').hasClass('active-user')) {
                $('.kennismakingsfiche').hide();
            } else {
                $('.kennismakingsfiche').show();
            }

            $(".kennismakingsfiche").find('a').attr('href', '/' + path);
        } else {
            $('.kennismakingsfiche').hide();
        }
    });

    // Custom file upload btn
    $("#file-upload-button:file").change(function () {
        var nr = $(this).get(0).files.length;

        if (nr > 1) {
            var fileName = nr + ' foto geselecteerd';
        } else {
            var fileName = $(this).val().replace('C:\\fakepath\\', '');
        }

        $("#file-upload-filename").html(fileName);
    });
    // $( "#datepicker-tot" ).datepicker({ dateFormat: 'dd/mm/yy', firstDay: 1 });


    // Pop-up event block
    $('.event-block').on('click', function(){
        var selected = $(this).attr('event-block');

        $('.overlay').fadeIn('fast');
        $('.pop-up-event.' + selected).show();
        $('body').css({overflow:'hidden'});
    });

    var active_block = 1;
    $('.events-overview.fa-angle-right').on('click', function(){
        var nr_blocks = $('.event-overview-block .event-block').length;
        if (((active_block) < nr_blocks) && ((active_block+1) < nr_blocks)) {
            $('.event-block.ev-' + (active_block-1)).addClass('hidden').hide();
            $('.event-block.ev-' + (active_block)).addClass('hidden').hide();

            $('.event-block.ev-' + (active_block+1)).removeClass('hidden').show();
            $('.event-block.ev-' + (active_block+2)).removeClass('hidden').show();

            active_block += 2;

        } else {

            // $('.event-overview-block .event-block').hide();
            //
            //  active_block = 2;
            //
            // $('.event-block.ev-' + (active_block-2)).removeClass('hidden').show();
            // $('.event-block.ev-' + (active_block-1)).removeClass('hidden').show();
            //
            // $('.event-block.ev-' + (active_block)).addClass('hidden').hide();
            // $('.event-block.ev-' + (active_block+1)).addClass('hidden').hide();
        }
    });

    $('.events-overview.fa-angle-left').on('click', function(){
        var nr_blocks = $('.event-overview-block .event-block').length;

        console.log(active_block, nr_blocks);
        if ((active_block-1 > 0) && active_block <= nr_blocks) {
            $('.event-block.ev-' + (active_block)).addClass('hidden').hide();
            $('.event-block.ev-' + (active_block-1)).addClass('hidden').hide();

            $('.event-block.ev-' + (active_block-2)).removeClass('hidden').show();
            $('.event-block.ev-' + (active_block-3)).removeClass('hidden').show();

            active_block -= 2;
        }
        console.log(active_block);

        // if (active_block >= nr_blocks) {
        //     active_block = 2;
        // }
        //
        // if ((active_block-2) >= 0) {
        //     console.log("if: " + active_block);
        //     $('.event-overview-block .event-block').hide();
        //
        //     $('.event-block.ev-' + 0).addClass('hidden').hide();
        //     $('.event-block.ev-' + 1).addClass('hidden').hide();
        //
        //     $('.event-block.ev-' + (active_block+1)).removeClass('hidden').show();
        //     $('.event-block.ev-' + (active_block)).removeClass('hidden').show();
        //
        //     active_block = (active_block-2);
        //
        // } else {
        //     active_block = 2;
        //     $('.event-block').hide();
        //     $('.event-block.ev-' + (active_block-2)).addClass('hidden').hide();
        //     $('.event-block.ev-' + (active_block-1)).addClass('hidden').hide();
        //
        //     $('.event-block.ev-' + (active_block-1)).removeClass('hidden').show();
        //     $('.event-block.ev-' + (active_block-2)).removeClass('hidden').show();
        //
        //     active_block -= 2;
        // }
    });

    $('.pop-up-event .fa-angle-right').on('click', function(){
        var total = $('.pop-up-event').length;
        var active = parseInt($(this).parent().attr('event-block'));


        if ((active + 1) >= total) {
            $('.overlay').hide();
            $('body').css({overflow: 'auto'});
        }

        if (active < total) {
            $('.pop-up-event.' + active).hide();
            $('.pop-up-event.' + (active+1)).show();
        } else {
            $('.pop-up-event.' + active).hide();
            $('.pop-up-event.1').show();
        }

        active += 1;
    });

    $('.pop-up-event .fa-angle-left').on('click', function(){
        var total = $('.pop-up-event').length;
        var active = parseInt($(this).parent().attr('event-block'));

        if (active - 1 < 0) {
            $('body').css({overflow: 'auto'});
            $('.overlay').hide();
        }

        $('.pop-up-event.' + (active-1)).show();
        $('.pop-up-event.' + active).hide();

        active -= 1;
    });

    $('.overlay, .fa-times').on('click', function(){
        $('.overlay').hide();
        $('.pop-up-event').hide();
        $('.beschrijving').hide();
        $('body').css({overflow:'scroll'});
    });

    $('input[name=filter]').on('keyup', function(){
        var val = $(this).val().toLowerCase();

        if (val != "") {
            $('.student-block').each(function(){
                $(this).hide();

                if ($(this).is('[class*=' + val + ']')) {
                    $(this).show();
                }
            });
        } else {
            $('.student-block').show();
        }
    });

    $('input[name=filter-lln]').on('keyup', function(){
        var val = $(this).val().toLowerCase();

        if (val != "") {
            $('.student-block').each(function(){
                $(this).hide();

                if ($(this).is('[class*=' + val + ']')) {
                    $(this).show();
                }
            });
        } else {
            $('.student-block').show();
        }
    });

    $('input[name=filter-link]').on('keyup', function(){
        var val = $(this).val().toLowerCase();

        if (val != "") {
            $('.lln-cb').each(function(){
                $(this).hide();

                if ($(this).is('[class*=' + val + ']')) {
                    $(this).show();
                }
            });
        } else {
            $('.lln-cb').show();
        }
    });

    $('.unlink').on('click', function(){
        var url = $(this).attr('real');
        $('.sure').show();

        $('.sure .yes-btn').attr('href', url);
    });

    $('.cancel').on('click', function(){
        $('.sure').hide();
    });


    if ($('#uploadBtn')[0]) {
        document.getElementById("uploadBtn").onchange = function () {
            document.getElementById("uploadFile").value = this.value.replace("C:\\fakepath\\", "");
        };
    }

    // if ($('.event-block')) {
    //     $('.event-block').each(function(){
    //         var event_height = $(this).height();
    //         $(this).find('.image-part').css({height: event_height});
    //     });
    // }

    $('.event-block').each(function(){
        var height = $(this).height();
        $(this).find('.image-part').css({height: height});
        $('.event-block.hidden').css({height: (height+20)});
    });

    if ($('.blog-post')) {
        var max_height = 0;
        $('.blog-post').each(function(){
            var event_height = $(this).height();

            if (event_height > max_height) {
                max_height = event_height;
            }

            $(this).css({height: max_height});
        });
    }

    $('.page-title').on('click', function(){
        var id = $(this).attr('block');
        $('.inner-block[block=' +  id +']').slideToggle();
        $(this).find('.fa').toggleClass('rotate');
    });

    $('.faq .category-block').click(function(){
        $(this).find('.answer').slideToggle();
        $(this).find('.fa').toggleClass('rotate');
        $(this).find('.question').toggleClass('red-font');
    });


    // tinymce.init({
    //     selector: 'textarea.text-editor',
    //     height: 1000,
    //     plugins: [
    //         'advlist autolink lists link image charmap print preview anchor',
    //         'searchreplace visualblocks code fullscreen',
    //         'insertdatetime media table contextmenu paste code'
    //     ],
    //     toolbar: 'insertfile undo redo | styleselect | bold italic | alignleft aligncenter alignright alignjustify | bullist numlist outdent indent | link image',
    //     content_css: '//www.tinymce.com/css/codepen.min.css'
    // });

    // Disable map scrolling
    $('.map-wrap').click(function () {
        $('.map-wrap iframe').css("pointer-events", "auto");
    });
    // Disable map scrolling
    $( ".map-wrap" ).mouseleave(function() {
      $('.map-wrap iframe').css("pointer-events", "none");
    });

    // Disable map scrolling
    $('.map-wrap').click(function () {
        $('.map-wrap #map').css("pointer-events", "auto");
    });
    // Disable map scrolling
    $( ".map-wrap" ).mouseleave(function() {
      $('.map-wrap #map').css("pointer-events", "none");
    });
});
